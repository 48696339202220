import {Link, LinkProps} from 'quickstart/components/content/Link'
import {DropdownMenu} from 'quickstart/components/controls/DropdownMenu'
import {MetaLink} from 'quickstart/components/tizra/MetaLink'
import {useSearch} from 'quickstart/hooks/useSearch'
import {useSearchConfig} from 'quickstart/hooks/useSearchConfig'
import styled from 'quickstart/styled-components/system'
import {ComponentProps, CSSProperties, JSX, MouseEvent, ReactNode} from 'react'
import {logger} from 'tizra'
import * as S from './styles'

const log = logger('NavLink')

// Don't include everything from Link, it just makes development more
// complicated.
export interface NavLinkLinkProps {
  children?: ReactNode
  label?: ReactNode
  href?: string
  onClick?: (e: MouseEvent<HTMLElement>) => void
  variant?: LinkProps['variant']
  // catch programming errors
  type?: never
  link?: never
  text?: never
}

// Styled for the sake of polymorphism
const NavLinkLink = styled(Link).attrs<NavLinkLinkProps>(
  ({children, label, variant = 'nav'}) => ({
    children: children || label || '',
    label: undefined,
    variant,
  }),
)`` as (props: NavLinkLinkProps) => JSX.Element

export type NavLinkAccountProps = NavLinkLinkProps

const NavLinkAccount = NavLinkLink

export type NavLinkBookshelfProps = NavLinkLinkProps

const NavLinkBookshelf = NavLinkLink

export interface NavLinkExpandoProps {
  metaTypes?: string[]
  adminTag?: string
  sortProp?: string
  sortDirection?: 'ascending' | 'descending'
  variant?: LinkProps['variant'] // ignored
  // catch programming errors
  type?: never
}

const NavLinkExpando = ({
  metaTypes = ['AdminTagged'],
  adminTag = '',
  sortProp = 'Title',
  sortDirection = 'ascending',
}: NavLinkExpandoProps) => {
  const searchConfig = useSearchConfig({
    mode: 'browse',
    metaTypes: {metadata: metaTypes},
    pre: {
      all: [adminTag],
    },
    fields: {
      depth: {defaultValue: 'metadata'},
      page: {defaultValue: 100},
      sort: {defaultValue: `sortProp-${sortDirection}`},
    },
    sorting: {sortProp},
  })
  const {results} = useSearch(searchConfig)
  if (!results) {
    return <DropdownMenu.Item>(none)</DropdownMenu.Item>
  }
  return (
    <>
      {results.map((r, i) => (
        <DropdownMenu.Item key={i} render={<MetaLink metaObj={r} />} />
      ))}
    </>
  )
}

export interface NavLinkMenuProps {
  label?: string
  items?: NavLinkProps[]
  menuProps?: Omit<ComponentProps<typeof DropdownMenu>, 'children' | 'label'>
  itemProps?: {
    style?: CSSProperties
  }
  variant?: LinkProps['variant'] // ignored
  // catch programming errors
  type?: never
}

const NavLinkMenu = ({
  label = '',
  items = [],
  menuProps,
  itemProps,
}: NavLinkMenuProps) => {
  return (
    <DropdownMenu label={label} variant="nav" {...menuProps}>
      {items.map((props, i) =>
        'items' in props || props.type === 'separator' ?
          <NavLink key={i} variant="unstyled" {...itemProps} {...props} />
        : <DropdownMenu.Item
            key={i}
            render={<NavLink variant="unstyled" {...props} />}
            {...itemProps}
          />,
      )}
    </DropdownMenu>
  )
}

export interface NavLinkSeparatorProps {
  variant?: LinkProps['variant'] // ignored
}

const NavLinkSeparator = ({variant, ...props}: NavLinkSeparatorProps) => (
  <DropdownMenu.Separator {...props} />
)

type WithType<T, U> = {type: T} & Omit<U, 'type'>

export type NavLinkAccountPropsWithType = WithType<
  'account',
  NavLinkAccountProps
>
export type NavLinkBookshelfPropsWithType = WithType<
  'bookshelf',
  NavLinkBookshelfProps
>
export type NavLinkExpandoPropsWithType = WithType<
  'expando',
  NavLinkExpandoProps
>
export type NavLinkLinkPropsWithType = WithType<'link', NavLinkLinkProps>
export type NavLinkMenuPropsWithType = WithType<'menu', NavLinkMenuProps>
export type NavLinkSeparatorPropsWithType = WithType<
  'separator',
  NavLinkSeparatorProps
>

export type NavLinkProps =
  | NavLinkAccountPropsWithType
  | NavLinkBookshelfPropsWithType
  | NavLinkExpandoPropsWithType
  | NavLinkLinkPropsWithType
  | NavLinkMenuPropsWithType
  | NavLinkSeparatorPropsWithType

const _NavLink = (props: NavLinkProps) => {
  switch (props.type) {
    case 'account': {
      const {type, ...rest} = props
      return <NavLinkAccount {...rest} />
    }
    case 'bookshelf': {
      const {type, ...rest} = props
      return <NavLinkBookshelf {...rest} />
    }
    case 'expando': {
      const {type, ...rest} = props
      return <NavLinkExpando {...rest} />
    }
    case 'link': {
      const {type, ...rest} = props
      return <NavLinkLink {...rest} />
    }
    case 'menu': {
      const {type, ...rest} = props
      return <NavLinkMenu {...rest} />
    }
    case 'separator': {
      const {type, ...rest} = props
      return <NavLinkSeparator {...rest} />
    }
    default: {
      const unreachable: never = props
      log.error('unreachable case:', unreachable)
      return (props as any).type ? null : <NavLinkLink {...(props as any)} />
    }
  }
}

export const NavLink = Object.assign(_NavLink, {Group: S.NavLinkGroup})

import * as AK from '@ariakit/react'
import * as R from 'rambdax'
import {ComponentProps, JSX, useEffect, useMemo, useState} from 'react'
import {
  CheckoutStep,
  debugging,
  IS_CHROMATIC,
  IS_STORYBOOK,
  logger,
  notNullish,
} from 'tizra'
import * as B from '../../block'
import {F} from '../../block'
import {CheckoutFormField} from './CheckoutFormField'

const log = logger('CartBlock/CheckoutForm')

interface FormSummaryProps
  extends Omit<ComponentProps<typeof B.Text>, 'children'> {
  step: CheckoutStep
  store: AK.FormStore
  keys?: string[]
}

const addColon = (s: string) => (/\w$/.test(s) ? s + ':' : s)

export const FormSummary = ({
  step,
  store,
  keys,
  ...props
}: FormSummaryProps) => {
  const fields = Object.fromEntries(
    step.infoRequired?.map(f => [f.name, f]) || [],
  )
  keys ||= Object.keys(fields)
  return (
    <B.Text {...props}>
      {keys
        .map(k => fields[k])
        .filter(f => f && f.prompt && notNullish(f.defaultValue))
        .map((f, i) => (
          <span key={i}>
            {addColon(f.prompt || f.name)} {f.defaultValue}
            <br />
          </span>
        ))}
    </B.Text>
  )
}

export interface UseCheckoutFormProps {
  active: boolean
  editing: boolean
  step: CheckoutStep
  submit: B.UseCheckoutReturn['submit']
  defaultValues?: Record<string, any>
}

export interface UseCheckoutFormReturn {
  active: boolean
  form: AK.FormStore
  step: CheckoutStep
  primaryButton: B.FormShellProps['primaryButton']
  secondaryButtons: B.FormShellProps['secondaryButtons']
  helpMessage: string | undefined
  errorMessage: string | undefined
  summary: JSX.Element
}

/**
 * Common submit and error handling for checkout forms. Returns props to pass to
 * <CheckoutForm>. This hook could properly be called useCheckoutFormProps but
 * that's too wordy.
 */
export const useCheckoutForm = ({
  active,
  editing,
  step,
  submit,
  defaultValues = Object.fromEntries(
    step.infoRequired?.map(f => [f.name, f.defaultValue]) || [],
  ),
}: UseCheckoutFormProps): UseCheckoutFormReturn => {
  const [errorMessage, setErrorMessage] = useState<string>()

  const restoreValues = useMemo(
    () => (editing ? defaultValues : undefined),
    [editing], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const form = F.useFormStore({defaultValues})

  form.useSubmit(async ({values}) => {
    const data = await submit(step.name, values)
    if (!data) return
    const {errors, message} = data
    setErrorMessage(message) // don't worry about reason
    if (errors) {
      form.setErrors(errors ? R.map(R.prop('message'), errors) : {})
    }
  })

  useEffect(() => {
    setErrorMessage(step.errors?.[0]?.message)
    const errors = Object.fromEntries(
      step.infoRequired
        ?.map(f => [f.name, f.error?.message])
        .filter(([, message]) => message) || [],
    )
    form.setErrors(errors)
  }, [form, step])

  return {
    active,
    helpMessage: step.infoPrompt,
    errorMessage,
    form,
    step,
    primaryButton: {
      type: 'submit',
      children: step.buttonPrompt || 'Continue',
      ...(active && B.tid(`${step.name}-continue`)),
    },
    secondaryButtons:
      !restoreValues ?
        []
      : [
          {
            children: 'Cancel',
            onClick: () => {
              form.setValues(restoreValues)
              form.submit()
            },
          },
        ],
    summary: (
      <FormSummary
        step={step}
        store={form}
        style={{display: !active ? undefined : 'none'}}
      />
    ),
  }
}

export interface CheckoutFormProps extends ComponentProps<typeof B.FormShell> {
  active: boolean
  step: CheckoutStep
  form: AK.FormStore
  summary: any
  debug?: boolean
}

/**
 * Render a checkout form and its summary. Expects props as returned from
 * useCheckoutForm(). Content of the form can be overridden for special cases by
 * passing children.
 */
export const CheckoutForm = ({
  active,
  children,
  form,
  step,
  summary,
  debug = !!debugging() || (IS_STORYBOOK && !IS_CHROMATIC),
  ...props
}: CheckoutFormProps) => {
  if (!active) return summary
  return (
    <F.Form store={form} debug={debug}>
      <B.FormShell variant="fluid" {...props}>
        {children ??
          step.infoRequired?.map(field => (
            <CheckoutFormField key={field.name} field={field} />
          ))}
      </B.FormShell>
    </F.Form>
  )
}

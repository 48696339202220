import {logger} from 'tizra'
import * as B from '../block'
import {CheckoutWizard} from './CheckoutWizard'
import {CartBlockConfig} from './meta'
import {OrderSummary} from './OrderSummary'
import {useBookshelfConfig} from '../BookshelfBlock'
import {useEffect} from 'react'

const log = logger('CartBlock/Checkout')

interface CheckoutProps {
  config: CartBlockConfig
}

const CheckoutModal = (props: NonNullable<B.UseCheckoutReturn['dialog']>) => {
  const modal = B.Modal.useStore({defaultOpen: true})

  // If action changes, then re-show the modal. This allows the modal to be
  // reused after it's closed.
  useEffect(() => modal.show(), [modal, props.action])

  return (
    <B.Modal store={modal} onClose={props.action} hideOnInteractOutside={false}>
      <B.Modal.Title>{props.title}</B.Modal.Title>
      <B.Modal.Content>{props.message}</B.Modal.Content>
      <B.Modal.Footer>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <B.Button onClick={modal.hide}>{props.button}</B.Button>
        </div>
      </B.Modal.Footer>
    </B.Modal>
  )
}

export const Checkout = ({config}: CheckoutProps) => {
  const userData = B.useUserData()
  const bookshelfConfig = useBookshelfConfig()

  const {
    checkouts,
    defaultCheckout,
    checkoutName,
    setCheckoutName,
    checkout,
    submit,
    loading,
    dialog,
  } = B.useCheckout({bookshelfConfig})

  return (
    <>
      <B.Section>
        <B.Grid cols={{_: 1, lg: 2}} colGap="48px">
          {userData === null || !checkouts ?
            <div />
          : <CheckoutWizard
              // Re-render from scratch whenever checkoutName changes.
              key={checkoutName || '_pick'}
              {...{
                config,
                userData,
                checkouts,
                defaultCheckout,
                checkoutName,
                setCheckoutName,
                checkout,
                submit,
              }}
            />
          }
          <OrderSummary checkout={checkout} config={config} />
        </B.Grid>
      </B.Section>
      {dialog && <CheckoutModal {...dialog} />}
      <B.Spinner loading={loading} fullscreen />
    </>
  )
}

import {Children, isValidElement, ReactNode} from 'react'

/**
 * Wrap text nodes in <span> so that styling can apply, for example, an asterisk
 * next to indicate required next to a label. This comes from welcome-ui.
 */
export const wrapChildren = (children: ReactNode) =>
  Children.toArray(children)
    .reduce((acc: ReactNode[], child) => {
      if (
        child &&
        typeof child === 'string' &&
        acc.length &&
        typeof acc[acc.length - 1] === 'string'
      ) {
        acc[acc.length - 1] += ' ' + child
      } else {
        acc.push(child)
      }
      return acc
    }, [])
    .map((child, i) =>
      isValidElement(child) ? child : <span key={i}>{child}</span>,
    )

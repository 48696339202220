import {Token, toTerms, toTokens} from 'quickstart/lib/search/terms'
import * as R from 'rambdax'
import {api, delay, logger, takeWhile} from 'tizra'
import {SearchConfig} from './config'
import {DEFAULTS} from './config/default-config'

const log = logger('search/autocomplete')

export interface AutoCompleteResult {
  phrase: string
  prefix: string
  full: string
  before: string
  suggestion: string
  after: string
  selectionStart: number
  selectionEnd: number
}

function createAutoComplete(searchConfig?: SearchConfig) {
  const config = {
    ...DEFAULTS.search.autoComplete,
    ...searchConfig?.autoComplete,
  }

  // Cache autoComplete results for the life of the caller
  const completers = new Map<string, ReturnType<typeof autoComplete2>>()

  // Track call that's still in the delay phase, since this should be
  // cancelled rather than forging ahead with the API call.
  let sleeper: any

  // autoComplete: Fetch completions for the input and cursor position.
  //
  // Returns a promise that resolves to an array of completion objects.
  // For example, given the arguments
  //
  //   ("special theo relativity", 12, 12)
  //
  // one completion in the array might be
  //
  //   {
  //      phrase: "theory",
  //      prefix: "theo",
  //
  //      // structured data for formatting
  //      before: "special theo",
  //      suggestion: "ry",
  //      after: " relativity",
  //
  //      // result if this completion is selected
  //      full: "special theory relativity",
  //      selectionStart: 14,
  //      selectionEnd: 14,  // new cursor position
  //   }
  //
  async function autoComplete(
    input: string,
    selectionStart: number,
    selectionEnd: number,
  ): Promise<AutoCompleteResult[] | null> {
    const key = JSON.stringify([input, selectionStart, selectionEnd])
    let completer = completers.get(key)

    if (!completer) {
      log.debug?.('making completer', key)
      const check = (sleeper = {}) // unique object
      await delay(config.delay)
      if (check === sleeper) {
        completer = autoComplete2(input, selectionStart, selectionEnd)
        completers.set(key, completer!)
      }
    }

    return completer || null
  }

  async function autoComplete2(
    input: string,
    selectionStart: number,
    selectionEnd: number,
  ): Promise<AutoCompleteResult[]> {
    const fullTextMetaType = searchConfig?.metaTypes?.fulltext?.[0] || 'PdfPage'

    log.debug?.(`fullTextMetaType is ${fullTextMetaType}`)

    // Normally selectionStart and selectionEnd are the same value, meaning
    // the location of the cursor in the input value, with nothing selected.
    // Don't try to generate completions if we're called and something is
    // actually selected.
    if (selectionStart !== selectionEnd) {
      log.debug?.(
        `aborting for selection (selectionStart=${selectionStart}, selectionEnd=${selectionEnd})`,
      )
      return []
    }

    // Parse the input into a list of tokens.
    // Each token is a normalized phrase (without leading plus, quotes, etc)
    // along with contextual information such as where it was found in the
    // input.
    const tokens = toTokens(input)
    log.debug?.({tokens})

    // Find the token for which we're generating completions.
    const prevTokens = takeWhile<Token>(t => t.index <= selectionEnd)(tokens)
    const _token = prevTokens.pop()
    if (!_token) {
      log.debug?.('no tokens before selectionEnd, aborting')
      return []
    }
    let token = _token! // convince TS that token can't be undefined

    if (config.unquotedPhraseHack) {
      // Handle spaces and short tokens (less than config.minChars) by
      // attempting to complete as unquoted phrases.

      if (selectionEnd > token.index + token.length) {
        // Cursor is outside of found token.
        // Try to complete this as an unquoted phrase.
        if (token.op || token.quotes || token.wild) {
          log.debug?.('unquoted phrase completion foiled by op/quotes/wild')
          return []
        }
        token.phrase += ' '
        token.length = selectionEnd - token.index
      }

      // If the current token isn't long enough for auto-completion, try
      // merging to previous token(s) for unquoted phrase completion.
      if (config.minChars) {
        while (token.phrase.length < config.minChars) {
          // Current token isn't long enough for auto-completion.
          // Try merging to previous token for unquoted phrase completion.
          const prev = prevTokens.pop()
          if (!prev) break
          const merged = token.merge(prev)
          if (!merged) break
          tokens.splice(prevTokens.length, 2, merged)
          log.debug?.({prevTokens, tokens, prev, token, merged})
          token = merged
        }
      }
    }

    // If the current token isn't long enough for auto-completion, return an
    // empty list.
    if (config.minChars && token.phrase.length < config.minChars) {
      return []
    }

    // prop-values API requires lower case
    const prefix = token.phrase.toLowerCase()

    // The API returns simple phrases. Convert the simple phrases into an
    // object with the necessary context so the caller will be able to insert
    // the phrase into existing input, preserving boolean operators and
    // adding (or removing) quotes as necessary.
    const makeCompletion = (phrase: string) => {
      const q = token.quotes ? '"' : ''
      const before =
        input.substring(0, token.index) + token.op + q + token.phrase
      const suggestion = phrase.substring(token.phrase.length) + q
      const after = input.substring(token.index + token.length)
      const full = before + suggestion + after
      const cursor = before.length + suggestion.length
      return {
        phrase,
        prefix,
        full,
        before,
        suggestion,
        after,
        selectionStart: cursor,
        selectionEnd: cursor,
      }
    }

    const apiParams: Parameters<typeof api.propValuesInfo>[0] = {
      metaType: fullTextMetaType,
      propNames: ['pageFullText'],
      limit: config.maxResults * 2 + 10,
      prefix,
    }

    if (tokens.length > 1) {
      const otherTokens = tokens.filter(t => t !== token)
      apiParams.all = [toTerms(otherTokens, fullTextMetaType, 'pageFullText')]
    }

    log.debug?.(apiParams)

    const r = await api.propValuesInfo(apiParams).catch(reason => {
      // Not sure why this started failing in CI.
      // https://app.circleci.com/pipelines/github/Tizra/evergreen/1490/workflows/ffa29925-c93b-4266-b4fa-c0e41bbc66f7/jobs/1306/parallel-runs/0/steps/0-109?invite=true#step-109-8241_30
      // Catch and log for debugging, but return empty array to avoid an
      // uncaught rejection.
      log.warn('autocomplete propValuesInfo failed:', reason)
      return []
    })
    return R.piped(
      Object.values(r)
        .flat()
        .map(x => x.value),
      phrases =>
        config.enablePhrases ? phrases : (
          phrases.filter(phrase => !phrase.match(/\s/))
        ),
      phrases =>
        config.enableStops ? phrases : (
          phrases.filter(phrase => !phrase.match(/\b_\b/))
        ),
      phrases => [...new Set(phrases)],
      phrases => phrases.slice(0, config.maxResults),
      R.map(makeCompletion),
      log.pipe('autoComplete2'),
    )
  }

  return autoComplete
}

export default createAutoComplete

/**
 * FormSelect modeled after example at https://ariakit.org/examples/form-select
 */

import * as AK from '@ariakit/react'
import {
  FocusEvent,
  FocusEventHandler,
  ReactNode,
  useContext,
  useRef,
} from 'react'
import {Field, FieldContext, FieldPropsBase} from './Field'
import * as S from './styles'

interface InnerSelectProps extends AK.SelectProps {
  value?: string
  setValue?: (value: string) => void
  defaultValue?: string
  onBlur?: FocusEventHandler<HTMLElement>
  options?: Array<{value: string; label: ReactNode}>
  placeholder?: ReactNode
}

const InnerSelect = ({
  children,
  value,
  setValue,
  defaultValue,
  options = [],
  placeholder = 'Choose from…',
  ...props
}: InnerSelectProps) => {
  const select = AK.useSelectStore({value, setValue, defaultValue})
  const portalRef = useRef<HTMLDivElement>(null)
  const selectValue = AK.useStoreState(select, 'value')
  const selectLabel = options.find(({value}) => value === selectValue)?.label

  const {size} = useContext(FieldContext)

  // Only call onBlur if the focus is leaving the whole widget.
  const onBlur = (event: FocusEvent<HTMLElement>) => {
    const portal = portalRef.current
    const {selectElement, popoverElement} = select.getState()
    if (portal?.contains(event.relatedTarget)) return
    if (selectElement?.contains(event.relatedTarget)) return
    if (popoverElement?.contains(event.relatedTarget)) return
    props.onBlur?.(event)
  }

  return (
    <>
      <AK.Select
        store={select}
        onBlur={onBlur}
        render={<S.Select />}
        {...props}
      >
        <span>{selectLabel ?? placeholder}</span>
        <AK.SelectArrow />
      </AK.Select>
      <AK.SelectPopover
        store={select}
        modal
        sameWidth
        onBlur={onBlur}
        portalRef={portalRef}
        render={<S.PickerPopover data-size={size} data-divided />}
      >
        {children}
        {options.map((o, i) => (
          <AK.SelectItem key={i} value={o.value} render={<S.PickerItem />}>
            {o.label}
          </AK.SelectItem>
        ))}
      </AK.SelectPopover>
    </>
  )
}

interface FormSelectProps
  extends AK.FormControlProps<'button'>,
    Omit<InnerSelectProps, 'options' | keyof AK.FormControlProps<'button'>> {
  options: Array<{
    value: string
    label: ReactNode
  }>
  required?: boolean
}

const FormSelect = ({
  name,
  ref,
  options,
  required = false,
  ...props
}: FormSelectProps) => {
  const form = AK.useFormContext()!
  const value = form.useValue(name)

  form.useValidate(() => {
    if (required && !form.getValue(name)) {
      form.setError(name, 'Please select an option.')
    }
  })

  const field = (
    <AK.FormControl
      name={name}
      render={
        <InnerSelect
          ref={ref}
          value={value ?? ''}
          setValue={value => form.setValue(name, value)}
          options={options}
        />
      }
    />
  )
  return <AK.Role.button {...props} render={field} />
}

interface SelectFieldProps
  extends FieldPropsBase,
    Omit<FormSelectProps, 'type'> {}

const SelectField = ({
  name,
  label,
  rightLabel,
  hint,
  size,
  type = 'select',
  ...props
}: SelectFieldProps) => (
  <Field
    name={name}
    label={label}
    rightLabel={rightLabel}
    hint={hint}
    size={size}
    type={type}
  >
    <FormSelect name={name} {...props} />
  </Field>
)

export type {FormSelectProps as SelectProps}

export const Select = Object.assign(SelectField, {Input: FormSelect})

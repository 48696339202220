import * as AK from '@ariakit/react'
import {Icon} from 'quickstart/components/content/Icon'
import {Image} from 'quickstart/components/content/Image'
import {Link} from 'quickstart/components/content/Link'
import {Container} from 'quickstart/components/layout/Container'
import {NavLink, NavLinkProps} from 'quickstart/components/layout/NavLink'
import {Stack} from 'quickstart/components/layout/Stack'
import {ProfileMenu} from 'quickstart/components/tizra/ProfileMenu'
import {QuickSearch} from 'quickstart/components/tizra/QuickSearch'
import {useApi, useIsomorphicLayoutEffect} from 'quickstart/hooks'
import {space} from 'quickstart/theme'
import {tid} from 'quickstart/utils'
import {ComponentProps, useState} from 'react'
import {UseProfileItemsProps, useNavItems, useProfileItems} from './hooks'
import * as S from './styles'

type MenuItemProps = ComponentProps<typeof NavLink>

const MenuItem = (props: MenuItemProps) => (
  <div
    style={{
      paddingLeft: space.lg,
      paddingRight: space.lg,
    }}
  >
    <NavLink {...props} />
  </div>
)

export interface NavProps {
  bookshelfConfig: UseProfileItemsProps['bookshelfConfig']
  logoLink: string
  logoUrl: string
  quickSearchPlaceholder: string
  includeCart: boolean
  includeSignIn: boolean
  nestedNavConfig: {items: NavLinkProps[]; profileItems: NavLinkProps[]}
  // protect agsint extra stuff from block config
  freemarker?: never
  nestedNavConfigJson?: never
  // old stuff from before nestedNavConfig
  links?: never
  profileMenu?: never
}

export const Nav = ({
  bookshelfConfig,
  logoLink,
  logoUrl,
  quickSearchPlaceholder,
  includeCart,
  includeSignIn,
  nestedNavConfig,
  ...props
}: NavProps) => {
  const [open, setOpen] = useState(false)

  const {profileName, profileItems} = useProfileItems({
    ...nestedNavConfig,
    bookshelfConfig,
    includeSignIn,
  })

  const {items: navItems} = useNavItems(nestedNavConfig)

  const cartSize = useApi.cart().data?.length
  const notifyCart = !!cartSize

  useIsomorphicLayoutEffect(() => {
    if (open) {
      const saved = document.body.style.overflow
      window.scrollTo(0, 0)
      document.body.style.overflow = 'hidden'
      return () => {
        document.body.style.overflow = saved
      }
    }
  }, [open])

  return (
    <AK.DisclosureProvider setOpen={setOpen}>
      <S.Nav {...props}>
        <S.LogoRow>
          <S.Logo>
            <Link href={logoLink} variant="unstyled">
              <Image loading="eager" src={logoUrl} />
            </Link>
          </S.Logo>

          <S.Mobile $display="flex">
            {!!includeCart && (
              // @ts-expect-error as=foo yikes
              <S.NavButton as={NavLink} type="link" href="/cart">
                <Icon icon="cart" size="100%" notify={notifyCart} />
              </S.NavButton>
            )}
            <AK.Disclosure
              render={
                <S.NavButton
                  right
                  icon={open ? 'close' : 'burger'}
                  {...tid('burger')}
                />
              }
            />
          </S.Mobile>

          <S.Desktop style={{flex: '0 0 30rem'}}>
            <QuickSearch>
              {({search}) => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{flex: '0 1 100%'}}>
                    <QuickSearch.Input
                      search={search}
                      placeholder={quickSearchPlaceholder || undefined}
                      size="md"
                    />
                  </div>
                  <div>
                    <QuickSearch.Button
                      render={
                        <S.NavButton
                          {...props}
                          icon="search"
                          height="48px"
                          width="54px"
                          py="10px"
                          px="10px"
                        />
                      }
                    />
                  </div>
                  {!!includeCart && (
                    // @ts-expect-error NavButton wants icon=string
                    <S.NavButton
                      as={NavLink}
                      height="48px"
                      width="54px"
                      py="10px"
                      px="10px"
                      type="link"
                      href="/cart"
                    >
                      <Icon icon="cart" size="100%" notify={notifyCart} />
                    </S.NavButton>
                  )}
                </div>
              )}
            </QuickSearch>
          </S.Desktop>
        </S.LogoRow>

        <AK.DisclosureContent render={<S.MenuHolder {...tid('mobile-menu')} />}>
          <S.Menu>
            <Container>
              <Stack divided spacing="lg">
                <div />
                {navItems.map((props, i) => (
                  <MenuItem key={`nav.${i}`} {...props} />
                ))}
                {!!includeCart && (
                  <MenuItem type="link" label="Cart" href="/cart" />
                )}
                {profileItems
                  .filter(item => item.type !== 'separator')
                  .map((props, i) => (
                    <MenuItem key={`profile.${i}`} {...props} />
                  ))}
                <div />
              </Stack>
            </Container>
          </S.Menu>
        </AK.DisclosureContent>

        <S.SearchRow>
          <S.SearchRowItems>
            <QuickSearch>
              {({search}) => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{flex: '0 1 100%', padding: '10px 0'}}>
                    <QuickSearch.Input
                      search={search}
                      size={'md' /* 44px tall */}
                      placeholder={quickSearchPlaceholder || undefined}
                      style={{
                        borderWidth: '1px', // XXX: clean up
                      }}
                    />
                  </div>
                  <QuickSearch.Button
                    render={<S.NavButton right icon="search" />}
                  />
                </div>
              )}
            </QuickSearch>
          </S.SearchRowItems>
        </S.SearchRow>

        <S.NavRow {...tid('desktop-menu')}>
          <NavLink.Group>
            {navItems.map((props, i) => (
              <NavLink key={i} {...props} variant="nav" />
            ))}
          </NavLink.Group>
          <NavLink.Group>
            <ProfileMenu profileName={profileName} items={profileItems} />
          </NavLink.Group>
        </S.NavRow>

        <S.NavDivider />
      </S.Nav>
    </AK.DisclosureProvider>
  )
}

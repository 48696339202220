import {NavLink, NavLinkProps} from 'quickstart/components/layout/NavLink'

interface ProfileMenuProps {
  profileName: string
  items: NavLinkProps[]
}

export const ProfileMenu = ({profileName, items}: ProfileMenuProps) => {
  if (!items.length) {
    return null
  }

  if (items.length === 1) {
    // @ts-expect-error _signin is a hack
    const {_signin, ...item} = items[0]
    if (_signin) {
      return <NavLink {...item} />
    }
  }

  return (
    <NavLink
      type="menu"
      label={profileName}
      items={items}
      menuProps={{placement: 'bottom-end', icon: 'user'}}
      itemProps={{style: {justifyContent: 'flex-end'}}}
    />
  )
}

import {formErrors} from 'quickstart/utils/final-form-errors'
import * as Final from 'react-final-form'
import {CheckoutsCheckout, logger} from 'tizra'
import * as B from '../block'
import {StepComponent} from './types'

const log = logger('CartBlock/PickCheckout')

const checkoutLabel = (cc?: CheckoutsCheckout) =>
  cc && (cc.displayName || cc.name)

export const PickCheckout: StepComponent = ({
  active,
  checkouts,
  checkoutName,
  setCheckoutName,
  setEditIndex,
}) => {
  if (!active) {
    return (
      checkoutName && (
        <p>{checkoutLabel(checkouts.find(cc => cc.name === checkoutName))}</p>
      )
    )
  }

  if (!checkouts.length) {
    return <p>There are no checkout methods configured.</p>
  }

  const options = checkouts.map(cc => ({
    value: cc.name,
    label: checkoutLabel(cc),
  }))

  const submit = async ({checkoutName}: {checkoutName: string}) => {
    if (checkouts.find(cc => cc.name === checkoutName)) {
      setCheckoutName(checkoutName)
      setEditIndex(null)
      return
    }
    return formErrors({errors: {checkoutName: {reason: 'required'}}})
  }

  return (
    <Final.Form
      onSubmit={submit}
      initialValues={{checkoutName}}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <B.FormShell variant="fluid">
            <B.ConnectedField
              component={B.Radios}
              name="checkoutName"
              options={options}
            />
            <B.Button type="submit">Continue</B.Button>
          </B.FormShell>
        </form>
      )}
    />
  )
}

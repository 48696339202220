import {Text} from 'quickstart/components/content/Text'
import styled, {css, th} from 'quickstart/styled-components/system'
import * as R from 'rambdax'
import {ComponentProps, FunctionComponent} from 'react'

// convert alignment to align-items or justify-content
const align = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

type _NavLinkGroupProps = {
  alignment?: 'left' | 'center' | 'right'
}

type NavLinkGroupProps = Omit<
  ComponentProps<typeof Text>,
  keyof _NavLinkGroupProps
> &
  _NavLinkGroupProps

// Note that alignment is only effective on a full-width div, for example in the
// Footer. If this is packed into a flexbox as a flex item, as in the top Nav,
// then it will occupy on the space it needs, and therefore alignment doesn't do
// anything.
export const NavLinkGroup = styled(Text)
  .attrs(R.mergeRight<any>({variant: 'nav'}))
  .withConfig({shouldForwardProp: p => p !== 'alignment'})<NavLinkGroupProps>(
  ({alignment = 'left'}) => css`
    flex: 0 0 auto;
    display: flex;

    // On mobile, display in a column.
    align-items: ${align[alignment!]};
    flex-flow: column;
    & > a:not(:first-child),
    & > button:not(:first-child) {
      margin-top: ${th('navLink.rowGap')};
    }

    // On desktop, display in a row.
    @media (min-width: ${th('breakpoints.md')}) {
      align-items: center;
      justify-content: ${align[alignment!]};
      flex-flow: row wrap; // allow wrap under duress
      & > a:not(:first-child),
      & > button:not(:first-child) {
        margin-top: 0; // undo mobile
        margin-left: ${th('navLink.colGap')};
      }
    }
  `,
) as FunctionComponent<NavLinkGroupProps>

import {logger} from 'tizra'
import {StepComponent} from '../types'
import {CheckoutForm, useCheckoutForm} from './CheckoutForm'

const log = logger('CartBlock/GenericStep')

export const GenericStep: StepComponent = props => {
  const checkoutForm = useCheckoutForm(props)
  return <CheckoutForm {...checkoutForm} />
}

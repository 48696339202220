import {ConnectedField} from 'quickstart/components/controls/ConnectedField'
import {Select} from 'quickstart/components/controls/Select'
import {Box} from 'quickstart/components/layout/Box'
import {Grid} from 'quickstart/components/layout/Grid'
import {SearchForm} from 'quickstart/components/tizra/SearchForm'
import {useHitCounts} from 'quickstart/hooks'
import {UseSearchReturn} from 'quickstart/hooks/useSearch'
import {
  asOptions,
  formFieldKey,
  formFields,
  SearchField,
} from 'quickstart/lib/search/config'
import {format} from 'quickstart/utils'
import {ReactNode, useMemo} from 'react'
import {logger, stripTags} from 'tizra'

const log = logger('Browse/BrowseFilters')

type BrowseFiltersConfigFieldProps = {
  field: SearchField<string> & {allOption?: {text: string; value: string}}
  search: UseSearchReturn
}

type Option = {value: string; text: string}

type LabeledOption = Option & {label: ReactNode}

const BrowseFiltersConfigField = ({
  field: _field,
  search,
}: BrowseFiltersConfigFieldProps) => {
  const hitCounts = useHitCounts({field: _field as SearchField, search}).data

  // Map incoming options {value, text} to {value, label, text}
  const labeledOptions = useMemo(() => {
    const options = asOptions(_field.options)
    const {prop, serverDef: {type} = {}} = _field
    const fmt: (o: Option) => ReturnType<typeof format> =
      !prop ? ({text}) => ({displayType: 'string', displayValue: text})
      : !type ?
        ({text}: {text: string}) => {
          // Strip tags while waiting for types to arrive.
          text = stripTags(text)
          return {
            displayType: 'string',
            displayValue: text,
            text,
          }
        }
        // TODO: no access to namePropName because of merged searchTypes
      : prop === 'Title' ?
        ({text}: {text: string}) =>
          format({
            value: text,
            type,
            options: {detectHtml: true, html: 'title'},
          })
      : ({text}: {text: string}) => format({value: text, type})
    return options
      .map(o => ({...o, ...fmt(o)}))
      .map(({value, text, displayType, displayValue}) => ({
        value,
        text: displayType === 'html' ? stripTags(text) : text,
        label:
          displayType === 'html' ?
            <span dangerouslySetInnerHTML={{__html: displayValue}} />
          : displayValue,
      }))
  }, [_field])

  const field = useMemo(() => {
    const {allOption = {text: 'All', value: ''}, options: _options} = _field
    const options = [
      ...((
        labeledOptions.some(
          o => o.value === allOption.value || o.text === allOption.text,
        )
      ) ?
        []
      : [{...allOption, label: allOption.text}]),
      ...(!hitCounts ? labeledOptions : (
        labeledOptions.filter(({value}) => hitCounts[value])
      )),
    ]
    return {..._field, options}
  }, [_field, hitCounts, labeledOptions])

  return (
    <ConnectedField
      name={field.name}
      component={Select}
      options={field.options}
      placeholder={field.placeholder || field.label}
      itemToString={(o: LabeledOption) => o.text}
      renderItem={(o: LabeledOption) => o.label}
    />
  )
}

type BrowseFiltersProps = {
  search: UseSearchReturn
}

export const BrowseFilters = ({
  search,
  search: {config},
}: BrowseFiltersProps) => {
  // Memoize so that fields/ffks don't change on every render, causing looping
  // renders via effects.
  const [fields, ffks] = useMemo(() => {
    const fields = formFields<string>(config, 'browseFilters')
    return [fields, fields.map(formFieldKey)]
  }, [config])

  return (
    // Note: breakpoints below for cols and width must match
    <SearchForm instant search={search}>
      <Grid cols={{md: fields.length}}>
        {fields.map((field, i) => (
          <Box
            key={ffks[i]}
            width={{xs: '100%', md: '16rem'}}
            justifySelf={
              fields.length === 1 ? 'center'
              : i === 0 ?
                'end'
              : 'start'
            }
          >
            <BrowseFiltersConfigField field={field} search={search} />
          </Box>
        ))}
      </Grid>
    </SearchForm>
  )
}

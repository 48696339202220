import * as AK from '@ariakit/react'
import classNames from 'classnames'
import {Text} from 'quickstart/components/content'
import {
  Button as ButtonComponent,
  ButtonProps as ButtonComponentProps,
} from 'quickstart/components/controls/Button'
import {useNextFrame} from 'quickstart/hooks'
import {borderWidths, space, toPx} from 'quickstart/theme'
import {ReactNode, Ref} from 'react'
import S from './styles.module.css'
import {IS_TEST} from 'tizra'

type MenuVariant = 'nav' | 'simple'

const useStore = (props?: AK.MenuStoreProps) => {
  const menu = AK.useMenuContext()
  const nested = !!menu?.parent
  return AK.useMenuStore({
    placement: nested ? 'right-start' : 'bottom-start',
    ...props,
  })
}

interface MenuProps extends AK.MenuProps {
  variant: MenuVariant
}

const Menu = ({variant, ...props}: MenuProps) => {
  const menu = AK.useMenuContext()
  const nested = !!menu?.parent
  const open = !!menu?.useState('open')
  const delayedVisible = useNextFrame(open) ? {} : {opacity: 0}
  return (
    <AK.Menu
      portal={!IS_TEST}
      fitViewport
      tabIndex={0}
      gutter={nested ? undefined : toPx(space.lg)}
      shift={nested ? -toPx(borderWidths.md) : undefined}
      data-variant={variant}
      render={<Text variant={variant === 'nav' ? 'nav' : undefined} />}
      {...props}
      className={classNames(S.menu, props.className)}
      style={{...delayedVisible, ...props.style}}
    />
  )
}

const Item = (props: AK.MenuItemProps) => (
  <AK.MenuItem {...props} className={classNames(S.item, props.className)} />
)

const Separator = (props: AK.MenuSeparatorProps) => (
  <AK.MenuSeparator
    {...props}
    className={classNames(S.separator, props.className)}
  />
)

interface ButtonProps
  extends AK.MenuButtonProps,
    Pick<ButtonComponentProps, 'variant' | 'icon' | 'rightIcon'> {}

const Button = ({
  variant = 'stealth',
  icon,
  rightIcon,
  ...props
}: ButtonProps) => {
  const nested = !!AK.useMenuContext()?.parent
  if (nested) variant = 'unstyled'
  const button = (
    <AK.MenuButton
      render={
        <ButtonComponent variant={variant} icon={icon} rightIcon={rightIcon} />
      }
      {...props}
      className={classNames(S.button, props.className)}
    />
  )
  return nested ?
      // If it's a submenu, we have to combine the MenuButton and the
      // MenuItem components into a single component, so it works as a
      // submenu button.
      <Item render={button} />
    : button
}

const buttonVariants: Record<MenuVariant, ButtonComponentProps['variant']> = {
  simple: 'stealth',
  nav: 'nav',
}

// Even though this appears to be a closed interface, JSX always allows dashed
// properties such as aria-label.
// https://github.com/microsoft/TypeScript/issues/32447
export interface DropdownMenuProps
  extends Pick<ButtonComponentProps, 'icon' | 'rightIcon'> {
  children: ReactNode | ((props: {store: AK.MenuStore}) => ReactNode)
  label: ReactNode
  placement?: AK.MenuStoreProps['placement']
  ref?: Ref<any>
  store?: AK.MenuStore
  variant?: MenuVariant
  chevron?: boolean
}

const _DropdownMenu = ({
  ref,
  children,
  label,
  placement,
  store: passedStore,
  variant = 'simple',
  chevron = true,
  icon,
  rightIcon = chevron ? <AK.MenuButtonArrow /> : undefined,
  ...props // e.g. aria-label
}: DropdownMenuProps) => {
  const createdStore = useStore({placement})
  const store = passedStore || createdStore

  return (
    <AK.MenuProvider store={store}>
      <Button
        icon={icon}
        rightIcon={rightIcon}
        variant={buttonVariants[variant]}
        ref={ref}
      >
        {label}
      </Button>
      <Menu {...props} variant="nav" ref={ref}>
        {typeof children === 'function' ? children({store}) : children}
      </Menu>
    </AK.MenuProvider>
  )
}

export const DropdownMenu = Object.assign(_DropdownMenu, {
  Button,
  Item,
  Separator,
  useStore,
})

import {
  SearchConfig,
  SearchField,
  SearchParams,
  isOptions,
} from 'quickstart/lib/search/config'
import {runHook} from 'quickstart/lib/search/hooks'
import searchParams from 'quickstart/lib/search/search-params'
import * as R from 'rambdax'
import {useEffect, useMemo, useState} from 'react'
import {shallowIdentical, sumPairs, truthy} from 'tizra'
import {useApi} from './useApi'
import {useEqualBy} from './useEqualBy'
import {UseSearchReturn} from './useSearch'

interface UseHitCountsProps {
  search: UseSearchReturn
  field: SearchField
  visible?: boolean
}

/**
 * React hook for fetching hit counts for a given field.
 */
export const useHitCounts = ({
  search,
  field: _field,
  visible = true,
}: UseHitCountsProps) => {
  const picked = useEqualBy(shallowIdentical, {
    ...R.pick(['configured', 'config', 'depths', 'params'], search),
    field: _field,
  })
  const [state, setState] = useState<typeof picked>()

  useEffect(() => {
    if (visible && state !== picked) {
      setState(picked)
    }
  }, [picked, state, visible])

  const {configured, config, depths, params, field} = state || {}
  const wantHits = field?.hits || (field?.hits !== false && !!field?.prop)

  const sps = useMemo(
    () =>
      wantHits && config && configured && depths && params ?
        fieldDepthSps({config, depths, field, params})
      : [],
    [config, configured, depths, field, params, wantHits],
  )

  // Make sure to call the hook all four times regardless, following the rules
  // of hooks. (Why four? See useSearch.)
  const queries = [
    useApi.propValuesInfo(sps[0]),
    useApi.propValuesInfo(sps[1]),
    useApi.propValuesInfo(sps[2]),
    useApi.propValuesInfo(sps[3]),
  ].slice(0, sps.length)

  const datas = useEqualBy(
    shallowIdentical,
    queries.map(q => q.data).filter(truthy),
  )

  const loading = wantHits && queries.some(q => q && q.isLoading)

  return useMemo(
    () => ({
      data: wantHits && datas.length ? sumHitCounts({field, datas}) : null,
      loading,
    }),
    [field, datas, loading, wantHits],
  )
}

function fieldDepthSps({
  config,
  depths,
  field,
  params,
}: {
  config: SearchConfig
  depths: SearchConfig['depths']
  field: SearchField
  params: SearchParams
}) {
  if (!isOptions(field.options)) {
    return []
  }

  const sps = depths
    .map(depth =>
      searchParams(
        config,
        {
          ...params,
          // Clear this field in the prop-values search parameters,
          // since we want to know what the counts WOULD BE if any
          // values on this field were set, rather than WHEN values on
          // this field are set.
          [field.name]: field.multi ? [] : '',
          depth,
        },
        {
          allowEmpty: true,
          // field.api.contribute can use this to customize the query for
          // efficiency.
          hittingOn: field.name,
          logName: `${config.mode}.useHitCounts.${field.name}.${depth}`,
        },
      ),
    )
    .filter(truthy)
    .map(sp => ({
      ...sp,
      propNames: [field.prop!],
    }))

  return sps
}

type ValueCount = Record<string, number>
type CVPairs = {count: number; value: string}[]

/**
 * Repackage ValueCount {v: c, v: c} -> CVPairs [{v, c}, {v, c}]
 */
const toCVPairs = (x: ValueCount): CVPairs =>
  Object.entries(x).map(([value, count]) => ({value, count}))

/**
 * Repackage  CVPairs [{v, c}, {v, c}] -> ValueCount {v: c, v: c}
 */
const toValueCount = (x: CVPairs): ValueCount =>
  Object.fromEntries(x.map(({value, count}) => [value, count]))

function sumHitCounts({
  field,
  datas,
}: {
  field: SearchField
  datas: Record<string, CVPairs>[]
}): ValueCount {
  // Flatten multiple API responses to array of pairs. Some values will have
  // multiple counts because of the multiple API calls.
  const unsummed: CVPairs = datas.flatMap(d => d?.[field.prop!]).filter(truthy)
  // => [{value: 'a', count: 1}, {value: 'a', count: 2}]

  // Reduce the multiple entries for each value into a single entry.
  let summed: ValueCount = sumPairs(unsummed.map(x => [x.value, x.count]))
  // => {value: 'a', count: 3}

  const hook = field.hooks?.hits
  if (hook) {
    summed = R.piped(
      summed,
      toCVPairs,
      hits => runHook(hook, {field, hits}),
      toValueCount,
    )
  }

  return summed
}

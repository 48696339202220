import {transient} from 'quickstart/styled-components/system'
import {UnprefixTransientProps} from 'quickstart/types'
import {ComponentProps} from 'react'
import * as S from './styles'

export type LinkProps = UnprefixTransientProps<
  Omit<ComponentProps<typeof S.Link>, 'color'>
>

export const Link = ({variant, ...props}: LinkProps) => (
  <S.Link {...transient({variant})} {...props} />
)

import {useNavigate} from 'react-router-dom'
import {Checkout, logger} from 'tizra'
import * as B from '../block'
import {CartItem} from './CartItem'
import {CartBlockConfig} from './meta'
import * as S from './styles'

const log = logger('CartBlock/OrderSummary')

interface OrderSummaryProps {
  checkout?: Checkout
  config: CartBlockConfig
}

export const OrderSummary = ({checkout}: OrderSummaryProps) => {
  const navigate = useNavigate()

  // Classic will pass empty infoItems in dummy checkout response. We need to
  // fetch cart here to calculate subtotal on our own.
  //
  // Additionally, if the user isn't signed in, or if the checkout picker is
  // shown, then there won't be a checkout response yet. In that case, we also
  // fetch the cart here so we can show the order summary.
  //
  // There's a race condition here where we'll start fetching the cart response
  // while checkout is on the way. It's not a big deal because we almost
  // certainly have the cart response already in react-query cache from being on
  // the cart screen.
  const {data: _cart} = B.useApi.cart(
    !checkout || checkout?.name === B.FULFILLER_BUTTONS_CHECKOUT_NAME,
  )
  const cart = _cart || checkout?.cart

  let infoItems = checkout?.infoItems
  if (!infoItems?.length && cart) {
    const currency =
      (cart[0] &&
        log.assert(
          cart[0].offer.currencyInfo,
          'missing currency-info in cart API response',
        )) ||
      'USD'
    const value = cart.reduce((sum, {offer: {price}}) => sum + price, 0)
    infoItems = [
      {
        type: 'sub-total',
        message: 'Subtotal',
        quantity: {
          value,
          format: {style: 'currency', currency},
        },
      },
    ]
  }

  if (!infoItems) return null

  return (
    <B.Stack spacing="xxl">
      <S.BorderedStack spacing="xxl">
        <B.LeftRight>
          <B.Text variant="h5">Order Summary</B.Text>
          <B.Text variant="textMd">
            <B.Link onClick={() => navigate('#cart')}>Edit bag</B.Link>
          </B.Text>
        </B.LeftRight>
        <B.Stack spacing="xs" divided>
          <B.Text>
            {cart?.length} item{cart?.length !== 1 && 's'}
          </B.Text>
          <B.Stack>
            {cart?.map((x: any, i: number) => (
              <CartItem {...x} variant="checkout" key={i} />
            ))}
          </B.Stack>
        </B.Stack>
      </S.BorderedStack>
      <B.Stack capped>
        {infoItems.map(({message, quantity, type}, i) => {
          // HACK: simplistic formatting for UMPH delivery
          const variant =
            type === 'total' || infoItems.length === 1 ? 'h5' : undefined
          return (
            <B.LeftRight key={i}>
              <B.Text variant={variant} as="div">
                {message}
              </B.Text>
              {quantity && (
                <B.Text variant={variant} as="div">
                  <B.Currency
                    amount={quantity.value}
                    currency={quantity.format?.currency || 'USD'}
                  />
                </B.Text>
              )}
            </B.LeftRight>
          )
        })}
      </B.Stack>
    </B.Stack>
  )
}

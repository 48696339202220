import {ReactNode, useMemo, useState} from 'react'
import {CheckoutStep as TCheckoutStep, logger, truthy} from 'tizra'
import {SetRequired} from 'type-fest'
import * as B from '../block'
import {CheckoutStep} from './CheckoutStep'
import {FulfillerButtons} from './FulfillerButtons'
import {PickCheckout} from './PickCheckout'
import {SignIn} from './SignIn'
import {CartBlockConfig} from './meta'
import * as S from './styles'
import {StepComponent} from './types'
import * as R from 'rambdax'

const log = logger('CartBlock/CheckoutWizard')

const CIRCLED_NUMBERS = [
  '⓪',
  '①',
  '②',
  '③',
  '④',
  '⑤',
  '⑥',
  '⑦',
  '⑧',
  '⑨',
] as const

const StepHeading = ({
  number,
  children,
}: {
  children: ReactNode
  number: number
}) => {
  const checkoutApiEnabled = B.useHack('checkoutApi')
  const checkoutNumbersEnabled = B.useHack('checkoutNumbers')
  return checkoutNumbersEnabled || !checkoutApiEnabled ?
      <S.NumberedHeading>
        <div>{CIRCLED_NUMBERS[number]}</div>
        <div>{children}</div>
      </S.NumberedHeading>
    : <S.Heading>{children}</S.Heading>
}

interface CheckoutWizardProps
  extends Omit<SetRequired<B.UseCheckoutReturn, 'checkouts'>, 'loading'> {
  config: CartBlockConfig
  userData: Exclude<ReturnType<typeof B.useUserData>, null>
}

export const CheckoutWizard = ({
  config,
  userData,
  checkouts,
  checkoutName,
  checkout,
  submit: _submit,
  ...props
}: CheckoutWizardProps) => {
  let displaySteps = useMemo<
    Array<{title: string; Component: StepComponent; step: TCheckoutStep}>
  >(() => {
    const checkoutSteps =
      checkout?.steps
        .filter(step => !step.hidden)
        .map(step => ({
          title: step.displayName ?? step.name,
          Component:
            step.name === B.FULFILLER_BUTTONS_STEP_NAME ?
              FulfillerButtons
            : CheckoutStep,
          step,
        })) || []
    const showPicker = !!userData && checkouts.length !== 1
    // TODO: Investigate TS enums to avoid these.
    const COMPLETE = 'complete' as const
    const INCOMPLETE = 'incomplete' as const
    return [
      {
        title: 'Sign in',
        Component: SignIn,
        step: {name: '_signin', status: userData ? COMPLETE : INCOMPLETE},
      },
      showPicker && {
        title: 'Checkout method',
        Component: PickCheckout,
        step: {name: '_pick', status: checkout ? COMPLETE : INCOMPLETE},
      },
      ...checkoutSteps,
    ].filter(truthy)
  }, [checkout, checkouts, userData])

  // Normally, the active step is the first one that isn't complete. But if the
  // user clicked Edit on a prior step, then that becomes the active step until
  // completed or canceled.
  let currentIndex = displaySteps.findIndex(
    ({step}) => step.status !== 'complete' || step.final,
  )
  if (currentIndex < 0) {
    log.error('Unexpected currentIndex:', currentIndex)
    currentIndex = 0
  }
  let [editIndex, setEditIndex] = useState<number | null>(null)
  if (editIndex !== null && editIndex > currentIndex) {
    log.error('Invalid combination:', {currentIndex, editIndex})
    editIndex = null
  }
  const activeIndex = editIndex ?? currentIndex
  const editing = editIndex !== null

  // Trim trailing steps so we're not showing headings with nothing else.
  displaySteps = R.dropLastWhile(
    ({step}) =>
      step !== displaySteps[currentIndex]?.step &&
      (step.final || step.status !== 'complete'),
    displaySteps,
  )

  const submit: typeof _submit = async (...args) => {
    const data = await _submit(...args)
    // If there's no data then reset manually-set index so we can proceed.
    if (!data) setEditIndex(null)
    return data
  }

  return (
    <B.Stack divided spacing="lg">
      {displaySteps.map(({title, Component, step}, stepIndex) => {
        const active = activeIndex === stepIndex
        const canEdit = !editing && activeIndex > stepIndex
        return (
          <B.Stack key={stepIndex}>
            <B.LeftRight>
              <StepHeading number={stepIndex + 1}>{title}</StepHeading>
              <B.Text variant="textMd">
                {canEdit ?
                  <B.Link onClick={() => setEditIndex(stepIndex)}>Edit</B.Link>
                : <span style={{visibility: 'hidden'}}>Edit</span>}
              </B.Text>
            </B.LeftRight>
            <Component
              active={active}
              userData={userData}
              config={config}
              setEditIndex={setEditIndex}
              myIndex={stepIndex}
              currentIndex={activeIndex}
              checkouts={checkouts}
              checkoutName={checkoutName}
              checkout={checkout}
              editing={editing}
              step={step}
              submit={submit}
              {...props}
            />
          </B.Stack>
        )
      })}
    </B.Stack>
  )
}

import * as AK from '@ariakit/react'
import * as S from './styles'
import {useFormContext} from './hooks'

export const Error = (props: AK.FormErrorProps) => {
  // Errors won't show until a field is touched, so force it.
  // https://github.com/ariakit/ariakit/issues/2818
  const store = useFormContext()!
  const error = AK.useStoreState(
    store,
    state => !!state.submitFailed && store.getError(props.name),
  )
  return (
    error && (
      <AK.FormError render={<S.Error />} {...props}>
        {error}
      </AK.FormError>
    )
  )
}

import * as R from 'rambdax'
import {logger} from 'tizra'
import * as B from '../../block'
import {StepComponent} from '../types'
import {CheckoutForm, useCheckoutForm} from './CheckoutForm'
import {CheckoutFormField, CheckoutFormFieldProps} from './CheckoutFormField'
import {countries, states} from './cokesbury'

const log = logger('CartBlock/UmphBillingStep')

type SK = keyof typeof states

const UMPH_FIELD_NAMES = [
  'first-name',
  'last-name',
  'email',
  'address1',
  'address2',
  'country',
  'city',
  'state',
  'postal-code',
  'phone',
] as const

type UmphFieldName = (typeof UMPH_FIELD_NAMES)[number]

const UMPH_FIELD_SET = new Set<string>(UMPH_FIELD_NAMES)

/**
 * Special handling for the UMPH billing step, especially per-country renaming
 * of fields and rendering some fields in columns.
 */
export const UmphBillingStep: StepComponent = props => {
  const checkoutForm = useCheckoutForm(props)
  const {step} = props
  const fields = Object.fromEntries(
    step.infoRequired?.map(f => [f.name, f]) || [],
  )
  const extraInfoRequired =
    step.infoRequired?.filter(f => !UMPH_FIELD_SET.has(f.name)) || []

  const inp = (
    name: UmphFieldName,
    {
      autoComplete,
      ...props
    }: Omit<CheckoutFormFieldProps, 'field'> & {label?: string} = {},
  ) => {
    const field = fields[name]
    return (
      field && (
        <CheckoutFormField
          key={field.name}
          field={field}
          autoComplete={autoComplete && `billing ${autoComplete}`}
          {...props}
        />
      )
    )
  }

  const defaultValues = R.map(f => f.defaultValue, fields)
  const country = defaultValues.country || 'USA'

  const summary = (
    <B.AddressSummary
      values={{
        ...defaultValues,
        firstName: defaultValues['first-name'],
        lastName: defaultValues['last-name'],
        zip: defaultValues['postal-code'],
        country: defaultValues['country'],
      }}
    />
  )

  return (
    <CheckoutForm {...checkoutForm} summary={summary}>
      <B.Flex>
        {inp('first-name', {autoComplete: 'given-name'})}
        {inp('last-name', {autoComplete: 'family-name'})}
      </B.Flex>
      {inp('country', {
        autoComplete: 'country',
        options: countries,
      })}
      {inp('address1', {
        autoComplete: 'address-line1',
        label: 'Street address',
      })}
      {inp('address2', {
        autoComplete: 'address-line2',
        label: 'Apartment, suite, etc. (optional)',
      })}
      <B.Flex>
        {inp('city')}
        {country in states ?
          inp('state', {
            label: country === 'CAN' ? 'Province' : 'State',
            options: states[country as SK],
          })
        : inp('state')}
        {inp('postal-code', {
          label: country === 'USA' ? 'Zip code' : 'Postal code',
        })}
      </B.Flex>
      {inp('email', {type: 'email', autoComplete: 'email'})}
      {inp('phone', {type: 'tel', autoComplete: 'tel'})}
      {Object.keys(extraInfoRequired).map(name => inp(name as UmphFieldName))}
    </CheckoutForm>
  )
}

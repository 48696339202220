import * as AK from '@ariakit/react'
import {Icon} from 'quickstart/components/content/Icon'
import {Text} from 'quickstart/components/content/Text'
import {tid} from 'quickstart/utils'
import {
  Children,
  cloneElement,
  ComponentProps,
  ReactElement,
  ReactNode,
  useState,
} from 'react'
import {logger} from 'tizra'
import S from './styles.module.css'
import classNames from 'classnames'

const log = logger('Accordion')

type AccordionProps = Omit<ComponentProps<'div'>, 'children' | 'title'> & {
  title: ReactNode
  defaultOpen?: boolean
  children: ReactNode | ((props: {visible: boolean}) => ReactNode)
  'data-testid'?: string
}

const _Accordion = ({
  children,
  'data-testid': dataTestId,
  defaultOpen = false,
  title,
  className,
  ...rest
}: AccordionProps) => {
  const [mounted, setMounted] = useState(defaultOpen)
  const inner =
    typeof children === 'function' ? children({visible: mounted}) : children

  return (
    <AK.DisclosureProvider
      defaultOpen={defaultOpen}
      setMounted={is => setMounted(was => was || is)}
    >
      <div
        className={classNames(S.accordion, className)}
        {...tid(dataTestId)}
        {...rest}
      >
        <AK.Disclosure
          className={S.trigger}
          render={<Text as="button" variant="facetHead" />}
        >
          {title}
          <Icon icon="chevronDown" className={S.icon} />
        </AK.Disclosure>
        <AK.DisclosureContent className={S.contentWrapper}>
          {/* https://ariakit.org/examples/disclosure-animated */}
          <div>
            <div className={S.content}>{inner}</div>
          </div>
        </AK.DisclosureContent>
      </div>
    </AK.DisclosureProvider>
  )
}

type GroupProps = Omit<ComponentProps<'div'>, 'children'> & {
  children: ReactElement<AccordionProps> | Array<ReactElement<AccordionProps>>
  defaultOpen?: number[] | true | false
}

const Group = ({
  children,
  defaultOpen = [0],
  className,
  ...props
}: GroupProps) => (
  <div {...props} className={classNames(S.group, className)}>
    {Children.map(children, (child, i) => {
      const open =
        Array.isArray(defaultOpen) ? defaultOpen.includes(i) : defaultOpen
      return cloneElement(child, {
        key: `${child.key}:${open}`, // make sure it rerenders
        defaultOpen: open,
      })
    })}
  </div>
)

export const Accordion = Object.assign(_Accordion, {Group})

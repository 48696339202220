import {ThemeProvider as StyledComponentsThemeProvider} from 'quickstart/styled-components/system'
import {createTheme as createStyledComponentsTheme} from 'quickstart/styled-components/theme/base'
import type {Branding, FontName} from 'quickstart/styled-components/theme/types'
import * as T from 'quickstart/theme'
import type {BlockContext} from 'quickstart/types'
import {ComponentProps, ReactNode, useMemo, useState} from 'react'
import {IconContext} from 'react-icons'
import {logger} from 'tizra'
import {
  ThemeBlockGlobalConfig,
  defaultGlobalConfig,
  globalMigrate,
} from './meta'
import * as AK from '@ariakit/react'
import S from './styles.module.css'
import {useIsomorphicLayoutEffect} from 'quickstart/hooks'

const log = logger('branding')

const hexy = (s: string) => /^#?(?:[\da-f]{3}){1,2}$/i.test(s)

const hash = (s: string) => s.replace(/^#?/, '#')

const fonts: Record<string, FontName> = {
  sans: 'Merriweather Sans',
  serif: 'Merriweather',
}

const validators = (globalConfig: ThemeBlockGlobalConfig) => ({
  color: (k: 'buttonColor' | 'linkColor') => {
    const x = globalConfig[k]
    if (x && typeof x === 'string') {
      if (hexy(x)) return hash(x)
      log.warn(
        `${k} doesn't match required hex color format #NNN or #NNNNNN, ignoring`,
      )
    }
    return defaultGlobalConfig[k]
  },
  font: (k: 'bodyFont' | 'headingFont') =>
    fonts[globalConfig[k]] ?? fonts[defaultGlobalConfig[k]],
})

/**
 * Create the theme for passing to styled-components ThemeProvider. The
 * structure passed to createTheme() from quickstart/styled-components/theme is
 * the set of overrides. The overrides from ThemeBlock fall under the branding
 * key.
 */
export const createBrandedStyledComponentsTheme = ({
  context,
}: {
  context: Pick<BlockContext, 'globalConfig'>
}) => {
  const globalConfig = globalMigrate({
    globalConfig: context.globalConfig.theme,
  })

  const validate = validators(globalConfig)

  const branding: Branding = {
    bodyFont: validate.font('bodyFont'),
    headingFont: validate.font('headingFont'),
    buttonColor: validate.color('buttonColor'),
    linkColor: validate.color('linkColor'),
  }

  return createStyledComponentsTheme({branding})
}

/**
 * Create the theme for passing to Linaria ThemeProvider. The structure passed
 * to createTheme() from quickstart/linaria/theme is slightly rearranged from
 * what we have in our global config.
 */
export const createBrandedLinariaTheme = ({
  context,
}: {
  context: Pick<BlockContext, 'globalConfig'>
}) => {
  const globalConfig = globalMigrate({
    globalConfig: context.globalConfig.theme,
  })

  const validate = validators(globalConfig)

  const branding: T.Branding = {
    colors: {
      button: validate.color('buttonColor'),
      link: validate.color('linkColor'),
    },
    fonts: {
      body: validate.font('bodyFont'),
      heading: validate.font('headingFont'),
    },
  }

  return T.createTheme(branding)
}

interface ThemeProviderThemes {
  linaria: NonNullable<ComponentProps<typeof T.ThemeProvider>['theme']>
  styledComponents: NonNullable<
    ComponentProps<typeof StyledComponentsThemeProvider>['theme']
  >
}

export const useLinariaTheme = ({
  context,
  theme,
}: {
  context: Pick<BlockContext, 'globalConfig'>
  theme?: ThemeProviderThemes['linaria']
}) =>
  useMemo(
    () =>
      theme ||
      createBrandedLinariaTheme({
        context:
          context ||
          (log.error('useLinariaTheme requires theme and/or context'),
          {globalConfig: {}}),
      }),
    [context, theme],
  )

export const useStyledComponentsTheme = ({
  context,
  theme,
}: {
  context: Pick<BlockContext, 'globalConfig'>
  theme?: ThemeProviderThemes['styledComponents']
}) =>
  useMemo(
    () =>
      theme ||
      createBrandedStyledComponentsTheme({
        context:
          context ||
          (log.error('useStyledComponentsTheme requires theme and/or context'),
          {globalConfig: {}}),
      }),
    [context, theme],
  )

type ThemeProviderProps = {
  context: Pick<BlockContext, 'globalConfig'>
  themes?: ThemeProviderThemes
  children?: ReactNode
}

export const ThemeProvider = ({
  context,
  themes,
  children,
}: ThemeProviderProps) => {
  const linariaTheme = useLinariaTheme({context, theme: themes?.linaria})
  const styledComponentsTheme = useStyledComponentsTheme({
    context,
    theme: themes?.styledComponents,
  })

  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null)
  useIsomorphicLayoutEffect(() => {
    let portalRoot =
      document.getElementById('t-my') ||
      document.getElementById('eg-portal-root')
    if (!portalRoot) {
      portalRoot = document.createElement('div')
      portalRoot.id = 'eg-portal-root'
      document.body.appendChild(portalRoot)
    }
    setPortalRoot(portalRoot)
  }, [setPortalRoot])

  return (
    <AK.PortalContext.Provider value={portalRoot}>
      <T.ThemeProvider theme={linariaTheme}>
        <StyledComponentsThemeProvider theme={styledComponentsTheme}>
          <IconContext.Provider value={{className: `icon ${S.icon}`}}>
            {children}
          </IconContext.Provider>
        </StyledComponentsThemeProvider>
      </T.ThemeProvider>
    </AK.PortalContext.Provider>
  )
}

import {ComponentProps, useState} from 'react'
import {logger, meta} from 'tizra'
import * as B from '../block'
import {StepComponent} from './types'

const log = logger('CartBlock/SignIn')

export const SignIn: StepComponent = ({active, userData, setEditIndex}) => {
  const authConfig = B.useAuthConfig()
  const [view, setView] =
    useState<ComponentProps<typeof B.Auth>['view']>('signin')

  const signedInAs =
    userData ?
      <B.Text>Signed in as {meta.userCheckoutName(userData)}</B.Text>
    : <B.Text>Not signed in.</B.Text>

  if (!active) {
    // This part of the Checkout accordion is closed.
    return signedInAs
  }

  if (!userData) {
    // We are active and signed out. Need to sign in!

    // TODO this is hacky, we're missing an abstraction somewhere.
    if (typeof window !== 'undefined' && window.tizra.customLoginUrl) {
      return (
        <B.Text>
          Please <B.Link onClick={() => window.tizra.login()}>sign in</B.Link>{' '}
          to continue.
        </B.Text>
      )
    }

    return (
      <B.Auth
        {...authConfig}
        autoFocus
        modal={false}
        show={setView}
        view={view}
        variant="checkout"
      />
    )
  }

  // We are active and signed in. Are you sure you want to sign out?
  return (
    <B.FormShell
      variant="fluid"
      primaryButton={{
        children: 'Sign out',
        onClick: () => void window.tizra.logout(),
      }}
      secondaryButtons={[
        {children: 'Cancel', onClick: () => setEditIndex(null)},
      ]}
    >
      <B.Prose>
        {signedInAs}
        <B.Text>
          <strong>
            If you sign out, you'll need to re-add items to your shopping bag.
          </strong>
        </B.Text>
      </B.Prose>
    </B.FormShell>
  )
}

import {themeGetter} from 'quickstart/styled-components/system'
import {bestContrast} from 'quickstart/utils'
import {partial} from 'rambdax'

const getButtons = (theme: any) => {
  const th = themeGetter(theme)

  const contrastingTextColor = partial(bestContrast, [
    [th('colors.textColor'), th('colors.textInvertedColor')],
  ])

  const base = {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    textDecoration: 'none',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none !important' /* important for firefox */,
    appearance: 'none',
    transition: th('transitions.medium'),
  }

  const primary = {
    backgroundColor: th('colors.buttonSolidColor'),
    borderWidth: th('borderWidths.md'),
    borderStyle: 'solid',
    borderColor: th('colors.buttonSolidColor'),
    borderRadius: th('radii.none'),
    margin: 0,
    padding: '8px', // default on Firefox
    color: contrastingTextColor(th('colors.buttonSolidColor')),
    ...th('ui.button'),
    /*
    '&:hover': {
      boxShadow: th('shadows.sm'),
    },
    '&:focus': {
      boxShadow: th('shadows.sm'),
    },
    */
  }

  const secondary = {
    ...primary,
    // This is transparent instead of inherit because the button might be
    // absolutely positioned, so it needs to get the color of whatever is
    // underneath, instead of its parent.
    backgroundColor: 'transparent',
    borderColor: th('colors.buttonOutlineColor'),
    color: th('colors.buttonOutlineColor'),
  }

  const tertiary = {
    ...primary,
    // This is transparent instead of inherit because the button might be
    // absolutely positioned, so it needs to get the color of whatever is
    // underneath, instead of its parent.
    backgroundColor: 'transparent',
    border: 'none',
    color: th('colors.textColor'),
    textTransform: 'uppercase',
    letterSpacing: th('letterSpacings.md'),
  }

  // Button variant="nav" looks like Link variant="nav", except that
  // it cooperates better with ReaKit MenuButton. HTML really wants links to
  // have hrefs because they're navigation, not controls, but then clicking
  // follows the href, and trying to cancel it with event.preventDefault()
  // breaks ReaKit. The right approach is to use a button instead of a link.
  const linksNav = th('links.nav')
  const nav = {
    backgroundColor: 'inherit',
    color: 'inherit',
    border: 'none',
    display: 'inline',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontWeight: 'inherit',
    height: 'auto',
    lineHeight: 'inherit',
    margin: 0,
    padding: 0,
    ...linksNav,
    ...(linksNav.textDecoration === 'underline' && {
      // text-decoration doesn't work well for buttons, because the content is
      // often a collection of text and icons in a flex container.
      textDecoration: 'none',
      borderBottomColor: 'transparent',
      borderBottomStyle: 'solid',
      borderBottomWidth: th('borderWidths.md'),
      marginBottom: `-calc(${th('borderWidths.md')})`,
      transition: `border-color ${th('transitions.medium')}`,
      '&:where(:hover)': {
        borderBottomColor: 'currentcolor',
      },
    }),
  }

  const _unstyled = {
    backgroundColor: 'inherit',
    color: 'inherit',
    border: 'none',
    display: 'inline',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontWeight: 'inherit',
    height: 'auto',
    lineHeight: 'inherit',
    margin: 0,
    padding: 0,
    textAlign: 'inherit',
  }

  // Button variant="stealth" looks like Link variant="stealth", except that
  // it cooperates better with ReaKit MenuButton. HTML really wants links to
  // have hrefs because they're navigation, not controls, but then clicking
  // follows the href, and trying to cancel it with event.preventDefault()
  // breaks ReaKit. The right approach is to use a button instead of a link.
  const stealth = {
    ..._unstyled,
    ...th('links.stealth'),
  }

  const unstyled = {
    ..._unstyled,
    ...th('links.unstyled'),
  }

  const buttons = {
    base,
    primary,
    secondary,
    tertiary,
    nav,
    stealth,
    unstyled,

    disabled: {
      primary: {
        backgroundColor: th('colors.buttonDisabled'),
        borderColor: th('colors.buttonDisabled'),
        color: th('colors.buttonDisabledColor'),
      },
      secondary: {
        backgroundColor: th('colors.buttonDisabled'),
        borderColor: th('colors.buttonDisabled'),
        color: th('colors.buttonDisabledColor'),
      },
      tertiary: {
        color: th('colors.buttonDisabledColor'),
      },
      nav: {
        color: th('colors.buttonDisabledColor'),
      },
      stealth: {
        color: th('colors.buttonDisabledColor'),
      },
      unstyled: {
        color: th('colors.buttonDisabledColor'),
      },
    },

    hover: {
      primary: {
        backgroundColor: th('colors.buttonSolidHover'),
        borderColor: th('colors.buttonSolidHover'),
      },
      secondary: {
        backgroundColor: th('colors.buttonOutlineHover'),
        borderColor: th('colors.buttonOutlineHover'),
        color: contrastingTextColor(th('colors.buttonOutlineHover')),
      },
      tertiary: {},
      nav: {}, // handled by links.nav
      stealth: {}, // handled by links.stealth
      unstyled: {},
    },

    focus: {
      primary: {
        // TODO this is barely different from the normal color.
        // Maybe there should be a focus ring?
        backgroundColor: th('colors.buttonSolidFocus'),
        borderColor: th('colors.buttonSolidFocus'),
      },
      secondary: {
        backgroundColor: 'transparent',
        borderColor: th('colors.buttonOutlineFocus'),
        color: th('colors.buttonOutlineFocus'),
      },
      tertiary: {
        backgroundColor: 'transparent',
        borderColor: th('colors.buttonNeutralFocus'),
        color: th('colors.buttonNeutralFocus'),
      },
      nav: {}, // handled by links.nav
      stealth: {}, // handled by links.stealth
      unstyled: {},
    },

    sizes: {
      // TODO height works better for some kinds of buttons than others
      xs: {
        height: '1rem',
        padding: `0 ${th('space.lg')}`,
      },
      sm: {
        height: '2rem',
        padding: `0 ${th('space.lg')}`,
      },
      md: {
        height: '3rem',
        padding: `0 ${th('space.lg')}`,
      },
      lg: {
        height: '3.5rem',
        padding: `0 ${th('space.xl')}`,
      },
    },
  }

  return buttons
}

export default getButtons

import * as AK from '@ariakit/react'
import {ComponentProps} from 'react'
import {Field, FieldPropsBase} from './Field'
import * as S from './styles'

type Mode = 'integer-null' | 'string'

type Parse = (s: string) => any

type Stringify = (v: any) => string

interface FormInputProps
  extends Omit<AK.FormControlProps<'input'>, 'onChange' | 'render' | 'value'> {
  mode?: Mode
  parse?: Parse
  stringify?: Stringify
}

const parsers: Record<Mode, Parse> = {
  'integer-null': s => {
    const v = parseInt(s)
    return isNaN(v) ? null : v
  },
  string: s => s,
}

const stringifiers: Record<Mode, Stringify> = {
  'integer-null': v => `${v ?? ''}`,
  string: v => `${v ?? ''}`,
}

const modeProps: Partial<Record<Mode, ComponentProps<typeof S.Input>>> = {
  'integer-null': {inputMode: 'numeric', pattern: '[0-9]*'},
}

const FormInput = ({
  name,
  mode = 'string',
  parse = parsers[mode],
  stringify = stringifiers[mode],
  ...props
}: FormInputProps) => {
  const form = AK.useFormContext()!
  const value = form.useValue(name)
  return (
    <AK.FormControl
      name={name}
      render={
        <S.Input
          {...modeProps[mode]}
          onChange={e => form.setValue(name, parse(e.target.value))}
          value={stringify(value)}
        />
      }
      {...props}
    />
  )
}

interface InputFieldProps
  extends Omit<FieldPropsBase, 'type'>,
    Omit<FormInputProps, 'size'> {
  fieldType?: FieldPropsBase['type']
}

const InputField = ({
  name,
  label,
  rightLabel,
  hint,
  size,
  fieldType = 'input',
  ...props
}: InputFieldProps) => (
  <Field
    name={name}
    label={label}
    rightLabel={rightLabel}
    hint={hint}
    size={size}
    type={fieldType}
  >
    <FormInput name={name} {...props} />
  </Field>
)

export type {InputFieldProps as InputProps}

export const Input = Object.assign(InputField, {Input: FormInput})

import {useRef} from 'react'
import {notNullish} from 'tizra'

/**
 * React hook to preserve defined value.
 */
export const useSticky = <T>(
  x: T,
  cond: (x: unknown) => boolean = notNullish,
): T => {
  const xRef = useRef<T>(x)
  if (cond(x)) {
    xRef.current = x
  }
  return xRef.current
}

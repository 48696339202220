import * as AK from '@ariakit/react'
import styled, {
  css,
  shouldForwardProp,
  system,
  SystemProps,
  th,
} from 'quickstart/styled-components/system'
import {ComponentProps} from 'react'

type ButtonProps = {
  disabled?: boolean
  $fluid?: boolean
  $hoverable?: boolean
  $size?: 'xs' | 'sm' | 'md' | 'lg'
  $variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'stealth'
    | 'nav'
    | 'unstyled'
} & SystemProps

// We have to strip 'as' from props before wrapping with styled, because of sc6
// bug that can't handle clash between 'as' definitions.
const _Button = (props: Omit<ComponentProps<typeof AK.Button>, 'as'>) => (
  <AK.Button {...props} />
)

export const Button = styled(_Button).withConfig({
  shouldForwardProp,
})<ButtonProps>(({$fluid, $hoverable, $size = 'lg', $variant = 'primary'}) => {
  return css`
    ${$variant === 'unstyled' ?
      css`
        // Match specificity of customerStyles button to override,
        // but still allow <Text render...> to override us.
        button:where(&) {
          ${th(`buttons.base`)};
          ${th(`buttons.${$variant}`)};
        }
      `
    : css`
        ${th(`buttons.base`)};
        ${th(`buttons.${$variant}`)};
      `};

    &:disabled {
      ${th(`buttons.disabled.${$variant}`)};
    }

    ${$variant !== 'stealth' &&
    $variant !== 'nav' &&
    $variant !== 'unstyled' &&
    th(`buttons.sizes.${$size}`)};

    ${$fluid &&
    css`
      width: 100%;
    `};

    // Space between icon and text.
    // span[aria-hidden] works for AK.MenuButtonArrow.
    & > *[data-icon-wrapper],
    & > span[aria-hidden] {
      &:first-child:not(:last-child) {
        margin-right: ${th('space.xs')};
      }
      &:last-child:not(:first-child) {
        margin-left: ${th('space.xs')};
      }
    }

    ${system};

    &:focus:not(:disabled) {
      ${th(`buttons.focus.${$variant}`)};
    }

    ${$hoverable &&
    css`
      &:hover:not(:disabled) {
        ${th(`buttons.hover.${$variant}`)};
      }
    `};
  `
})

import {textComponent} from 'quickstart/components/content/Text'
import {toRem, withTheme} from 'quickstart/theme'
import {styled, theme} from 'quickstart/theme/linaria'

const indent = theme.space.lg

const _Form = styled.form`
  display: grid;
  column-gap: ${theme.space.xl};
  row-gap: ${theme.space.xxl};
`
export const Form = withTheme(_Form)

// Apply form font, since it's harder to apply in Input, Select, etc. This could
// be reverted to body font by an internal component as needed. We don't apply
// in Form above because it's hard to combine Text and AK.Form.
const _Field = styled(textComponent({font: 'ui', variant: 'form'}))`
  display: flex;
  flex-direction: column;

  ${_Form}[data-size=sm] &:not([data-size]),
  &[data-size=sm] {
    --field-input-height: 2rem;
    --field-row-gap: ${theme.space.xxs};
  }
  ${_Form}[data-size=md] &:not([data-size]),
  &[data-size=md] {
    --field-input-height: 3rem;
    --field-row-gap: ${theme.space.xs};
  }
  ${_Form}[data-size=lg] &:not([data-size]),
  &[data-size=lg] {
    --field-input-height: 3.5rem;
    --field-row-gap: ${theme.space.sm};
  }

  gap: var(--field-row-gap);
`
export const Field = withTheme(_Field)

// TODO: old fieldStyles had variant colors, transition

const _Input = styled(
  textComponent({
    font: 'ui',
    variant: 'form',
    as: 'input',
  }) as unknown as 'input',
)`
  width: 100%;
  min-height: var(--field-input-height);
  padding: ${theme.space.xs} ${indent};

  // Ariakit examples use box-shadow instead of border
  border: ${theme.borders.std};
  border-radius: 0;

  &::placeholder {
    color: ${theme.colors.textColorMuted};
  }

  &:focus-visible,
  &[data-focus-visible] {
    border-color: ${theme.colors.borderFocusColor};
  }
`
export const Input = withTheme(_Input)

export const Buttons = withTheme(styled.div`
  display: flex;
  gap: ${theme.space.xl};
`)

export const LabelWrapper = withTheme(styled.div<{
  'data-indent-label'?: true
}>`
  line-height: 1rem; // XXX compat with old
  &[data-indent-label] {
    margin-inline: ${indent};
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${theme.space.xxl};
`)

// This is rendered as either label or span. See Label.tsx
export const Label = styled.label``

export const RightLabel = styled.div`
  &:empty {
    display: none;
  }
`

export const Hint = withTheme(styled(
  textComponent({font: 'ui', variant: 'formMeta', italic: true}),
)`
  margin-inline: ${indent};
  &:empty {
    display: none;
  }
`)

const _Select = styled(
  textComponent({
    font: 'ui',
    variant: 'form',
    as: 'button',
  }) as unknown as 'button',
)`
  display: grid;
  grid-template-columns: 1fr min-content;
  width: 100%;
  height: var(--field-input-height);
  user-select: none;
  align-items: center;
  gap: 0.25rem; // XXX
  text-align: left; // override default for button
  // Ariakit examples use box-shadow instead of border
  border: ${theme.borders.std};
  border-radius: 0;
  padding: 0 ${indent};
  background-color: inherit;
  color: inherit;
  text-decoration-line: none;

  &[aria-disabled='true'] {
    color: ${theme.colors.textColorMuted};
  }

  &[data-focus-visible],
  &[aria-expanded='true'] {
    border-color: ${theme.colors.borderFocusColor};
  }

  @media (min-width: 640px) {
    & {
      gap: 0.5rem;
    }
  }

  & > *:first-child {
    height: 1lh;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export const Select = withTheme(_Select)

export const PickerItem = styled.div``

// This builds on Field for the sake of size control.
export const PickerPopover = withTheme(styled(_Field)<{'data-divided'?: true}>`
  z-index: 50;
  display: flex;
  max-height: min(var(--popover-available-height, 300px), 300px);
  flex-direction: column;
  gap: 0; // cancel gap set by surrounding Field
  overflow: auto;
  overscroll-behavior: contain;
  border: ${theme.borders.std};
  border-top: none;
  border-radius: 0;
  background-color: ${theme.colors.backgroundColorWhite};
  color: ${theme.colors.textColor};

  &:empty {
    display: none;
  }

  // This complicated calculation ensures we use the same padding regardless
  // of the number of lines. The normal case of a single line will have a final
  // height of --field-input-height.
  --picker-gap: calc((var(--field-input-height) - 1lh) / 2);

  & > ${PickerItem} {
    display: flex;
    cursor: default;
    align-items: center;
    outline: none !important;

    // Without dividers, use half padding.
    padding: calc(var(--picker-gap) / 2) ${indent};

    &[aria-disabled='true'] {
      color: ${theme.colors.textColorMuted};
      cursor: not-allowed;
    }

    &[aria-selected='true'] {
      font-weight: ${theme.fontWeights.bold};
    }

    &[data-active-item] {
      background-color: ${theme.colors.backgroundColorLight};
    }
  }

  &[data-divided] > ${PickerItem} {
      padding: var(--picker-gap) ${indent};

      border-top: ${theme.borderWidths.sm} solid ${theme.colors.borderColorLight};
      &:first-child {
        border-top: none;
      }
    }
  }
`)

export const _Checklist = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.xs};
`
export const Checklist = withTheme(_Checklist)

export const CheckLabel = withTheme(styled.label`
  --checkbox-size: 1.125em;
  display: grid;
  grid-template-columns: var(--checkbox-size) auto;
  gap: ${theme.space.xs};
  display: flex;
  user-select: none;
  align-items: baseline;
  color: ${theme.colors.textColor};
  cursor: pointer;

  ${_Checklist} &[data-checked='true'] {
    font-weight: ${theme.fontWeights.bold};
  }

  --border: ${theme.colors.borderColor};

  &:hover,
  &[data-checked='true'],
  &[data-focus-visible='true'] {
    --border: ${theme.colors.borderSelectedColor};
  }

  &[data-disabled='true'] {
    --border: ${theme.colors.borderColor};
    color: ${theme.colors.textColorMuted};
    cursor: not-allowed;
  }
`)

const checkboxSvg = `<svg stroke-width="0" viewBox="0 0 512 512" height="0.875em" width="0.875em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>`

export const Check = withTheme(styled.div`
  position: relative;
  top: 2px;
  display: block;
  width: ${toRem(18)};
  height: ${toRem(18)};
  flex-shrink: 0;
  border: ${theme.borders.std};
  border-color: var(--border);
  border-radius: 0;
  background-color: ${theme.colors.backgroundColorWhite};
  line-height: ${toRem(16)};

  &:after {
    content: '';
    mask: url('data:image/svg+xml; utf8, ${checkboxSvg}') no-repeat 50% 50%;
    mask-size: cover;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: background-color ${theme.transitions.fast};
  }

  &[data-checked='true']:after {
    background-color: ${theme.colors.buttonSolidFocus};
  }
`)

export const _Radio = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.xs};
`
export const Radio = withTheme(_Radio)

export const RadioWrapper = withTheme(styled.label<{
  'data-disabled'?: true
}>`
  --radio-size: 1.125em;
  display: grid;
  grid-template-columns: var(--radio-size) auto;
  gap: ${theme.space.xs}; // TODO? .5em would scale
  transition: ${theme.transitions.fast};
  color: ${theme.colors.textColor};
  cursor: pointer;

  --border: ${theme.colors.borderColor};

  &:hover,
  &[data-focus-visible='true'] {
    --border: ${theme.colors.borderSelectedColor};
  }

  &[data-disabled] {
    --border: ${theme.colors.borderColor};
    color: ${theme.colors.textColorMuted};
    cursor: not-allowed;
  }
`)

export const RadioColumn = styled.div`
  height: 0;
  width: 0;
  overflow: visible;
`

export const RadioButton = withTheme(styled.input<{disabled?: boolean}>`
  appearance: none;

  /* For iOS < 15 to remove gradient background. */
  background-color: ${theme.colors.backgroundColorWhite};

  /* Not removed via appearance. */
  margin: 0;
  font: inherit;
  color: currentColor;

  /* Fit in container provided by Wrapper grid. */
  width: var(--radio-size);
  height: var(--radio-size);

  /* Attempt to line up with text label. This seems to work pretty well
   * regardless of line-height (varies between textMd and form).
   */
  display: inline-grid;
  vertical-align: baseline;
  transform: translateY(calc(${theme.borderWidths.md} * -0.5));

  /* Put the dot in the middle of the circle. */
  place-content: center;

  &::before {
    content: '';
    width: calc(var(--radio-size) * 0.55);
    height: calc(var(--radio-size) * 0.55);
    border-radius: 50%;
    transform: scale(0);
    transition: 100ms transform ease-in-out;
    box-shadow: inset 1em 1em ${theme.colors.buttonSolidFocus};
  }

  &:checked::before {
    transform: scale(1);
  }

  border: ${theme.borderWidths.md} solid var(--border);
  border-radius: 50%;

  &:checked {
    border-color: ${theme.colors.borderSelectedColor};
  }
`)

export const RadioLabel = withTheme(styled.div<{
  'data-checked'?: boolean
}>`
  &[data-checked] {
    font-weight: ${theme.fontWeights.bold};
  }
`)

export const Error = withTheme(styled.div`
  padding: ${theme.space.md};
  border: ${theme.borders.std};
  border-color: ${theme.colors.borderError};
  background-color: ${theme.colors.backgroundError};
  color: ${theme.colors.textError};

  // Negative margin-top to cancel gap between input and error directly under.
  :where(${_Input}, ${_Select}) ~ & {
    margin-top: calc(-1 * var(--field-row-gap));
  }

  &:empty {
    display: none;
  }
`)

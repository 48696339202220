import {logger} from 'tizra'
import * as B from '../../block'
import {StepComponent} from '../types'
import {CheckoutForm, FormSummary, useCheckoutForm} from './CheckoutForm'

const log = logger('CartBlock/UmphStep')

/**
 * Special handling for UMPH steps (except billing).
 */
export const UmphStep: StepComponent = props => {
  const {step} = props
  const checkoutForm = useCheckoutForm(props)
  let {summary} = checkoutForm

  if (step.name === 'payment-options') {
    const defaultValues = Object.fromEntries(
      step.infoRequired?.map(f => [f.name, f.defaultValue]) || [],
    )
    const paymentMethod = defaultValues['payment-method']
    if (paymentMethod === 'invoice' || paymentMethod === 'paypal') {
      summary = (
        <B.Text>
          {paymentMethod === 'invoice' ?
            'Bill to Cokesbury account'
          : 'Pay now with PayPal or credit cart'}
        </B.Text>
      )
    }
  }

  if (step.name === 'invoicing' && step.status === 'complete') {
    // Only display the account number in the summary
    summary = (
      <FormSummary
        step={step}
        store={checkoutForm.form}
        keys={['cokesbury-account']}
      />
    )
  }

  return <CheckoutForm {...checkoutForm} summary={summary} />
}
